<template>
  <div
    class="h-12 bg-base-200 w-11/12 rounded-xl shadow-2xl flex mx-auto justify-center gap-4 px-2 items-center bottom-nav"
  >
    <!-- <routerLink to="/admin/nfc" v-if="!user.isPremiumUser">
      <BottomNavigationItem active="admin-NFC">
				<icon	
					name="mdi:nfc"
					size="20"
				/>
			</BottomNavigationItem>
    </routerLink> -->
    <routerLink to="/admin/space">
      <BottomNavigationItem :active="['admin-space', 'admin-space-name']">
        <icon name="cib:myspace" size="20" />
      </BottomNavigationItem>
    </routerLink>
    <routerLink to="/admin">
      <BottomNavigationItem active="admin">
        <icon name="fluent:card-ui-24-filled" size="20" />
      </BottomNavigationItem>
    </routerLink>
    <a :href="`/${user?.name}?preview=true`">
      <BottomNavigationItem active="admin-preview">
        <icon name="material-symbols:preview" size="20" />
      </BottomNavigationItem>
    </a>

    <routerLink to="/admin/analytics">
      <BottomNavigationItem active="admin-analytics">
        <icon name="majesticons:analytics" size="20" />
        <span
          class="dark:text-white item overflow-hidden hidden transition-all duration-200"
          >Analytics</span
        >
      </BottomNavigationItem>
    </routerLink>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useUserStore } from "~/stores/user";
const { user } = storeToRefs(useUserStore());
</script>

<style scoped>
@media (max-width: 400px) {
  .bottom-nav {
    gap: 0.3rem;
  }
}
</style>
